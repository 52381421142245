import posApi from '../../api/pos';

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
	async validateTableSession(context, { token, data }) {
		const res = await posApi.validateTableSession(token, data);
    if (res?.status !== 200) {
      return false;
    }
    return true;
	},
};

export default {
  namespaced: true,
  mutations,
  state,
  getters,
  actions,
};
